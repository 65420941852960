import { Space, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import SeasonService from "../../services/Season";
import { useTranslation } from "react-i18next";
import { upperCaseFirstLetter } from "../../utilities/format";
import { GlobalContext } from "../../App";
import Stylesheet from "reactjs-stylesheet";

const itemsPerColumn = 5;
const itemHeight = 40; // Adjust based on your item's height

const CropMapLegendSeasons = () => {
  const { t, i18n } = useTranslation("translation");

  const [seasons, setSeasons] = useState(null);

  const {selected_user_asset} = useContext(GlobalContext)

  useEffect(() => {
    const fetchSeasons = async () => { 
      const _seasons = await SeasonService.getSeasons()
      console.log("Seasons: ", _seasons);
      setSeasons(_seasons)
    }

    fetchSeasons()
  }, [])


    
  return (
    <>
      <div style={{backgroundColor: "transparent", left: 0, bottom: 0, overflow: "scroll"}}>
        <Space direction="vertical" size={"small"} styles={{item: {padding: 0, margin: 0, lineHeight: "1rem", ...styles.containerStyle}}} style={{rowGap: 0}}>
          {
            seasons?.map((season) => {
              const legend_item_name = `${season.name} (${t(`components.cropclasses.${season.planted_crop.cropClass}`)} / ${upperCaseFirstLetter(t(`components.crops.${season.planted_crop.name}`))})`
              return (
                <Space direction="horizontal" style={{columnGap: 0, ...styles.itemStyle}} ><div className="color" style={{backgroundColor: season.color}}></div><Typography.Text className="label" style={{color: selected_user_asset && "white" || "black"}}>{legend_item_name}</Typography.Text></Space>
              ) 
            })
          }
        </Space>
      </div>
    </>
  )
}


const styles = Stylesheet.create({
  containerStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    breakInside: "avoid",
    maxHeight: `${itemsPerColumn * itemHeight}px`,
    alignContent: 'flex-start',
  },
  
  itemStyle: {
    //width: '200px', // Adjust based on desired column width
    //height: `${itemHeight}px`,
  }
})

export default CropMapLegendSeasons;

