import React, {useState, useEffect, useContext} from "react";
import { Layout, List, Space, Divider, Dropdown, Avatar, Typography, Input, DatePicker, Button, Empty, Timeline, Spin, Tooltip} from 'antd';
import { DownOutlined, ClockCircleOutlined, RightOutlined, EllipsisOutlined} from '@ant-design/icons';
import NotesService from "../../services/NotesService";
import moment from 'moment';
import FieldControlProvisionsService from "../../services/FieldControlProvisions";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../App";
import FieldHealthPieChart from "../vegetation-indices/ndvi/FieldHealth";
import { StatisticCard } from '@ant-design/pro-components';
import IndexGraph from "../vegetation-indices/IndexGraph";
import RcResizeObserver from 'rc-resize-observer';

const { Search } = Input;
const { Statistic } = StatisticCard;
const StatisticDivider = StatisticCard.Divider;

const _menu_props = {
  'all': "All notes and annotations",
  'timeline': "Timeline"
}

function Notes(props) {
  const { t, i18n } = useTranslation("translation");

  //TODO - Doesn't support notes and annotations differently - all notes are also annotations for now
  const [selected_submenu, setSelectedSubMenu] = useState({type: "all"});

  const [responsive, setResponsive] = useState(false);

  const [notes, setNotes] = useState([]);
  const [timeline_items, setTimelineItems] = useState([]);
  const [filtered_notes, setFilteredNotes] = useState([]);
  const [notes_loading, setNotesLoading] = useState(false);
  const [field_id, setFieldId] = useState(props.field_id);
  const [provisions, setProvisions] = useState([]); 

  const [annotations, setAnnotations] = useState([]);

  //Contexts
  const {
    selected_index,
    selected_index_overlay,
    selected_user_asset,
    current_view
  } = useContext(GlobalContext);

  useEffect(()=>{
    const fetchNotes = async () => {
      setNotesLoading(true);
      let _notes = "";
      if(field_id) {
        //_notes = await NotesService.getNotesByField(field_id)
        FieldControlProvisionsService.getAllProvisions({field: {id:field_id}, page: 1, where: "notes"}, _provisions => {
          setProvisions(_provisions.data);
        })
        return;
      } else {
        _notes = await NotesService.getAllNotes()
      }

      if (_notes || _notes.length > 0) {
        var _annotations = {};

        _notes.reverse().map((note) => {
          if (!_annotations[note.annotationDate]) {
              _annotations[note.annotationDate] = [];
          } 

          _annotations[note.annotationDate].push(note.user);
        })

        setAnnotations(_annotations); 
      }

      _notes = _notes.reverse();
      setNotes(_notes);

      var _timeline = _notes.map((item) => {
        return {
          children: 
            <>
              <Typography.Text danger italic style={{fontSize: 12}}>({item.annotationDate}) </Typography.Text>
              <Typography.Text>{item.content}</Typography.Text>
            </>,
          annotation_date: item.annotationDate,
          //dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />
          //dot: item.annotationDate
          color: "tangerine",
          //dot: <Avatar size="small" style={{ backgroundColor: '#f56a00' }}>{item.user.name[0]}</Avatar>

        }
      })

      _timeline.sort(function(a, b) {
        var keyA = new Date(a.annotation_date),
          keyB = new Date(b.annotation_date);
        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });

      setTimelineItems(_timeline)
      setFilteredNotes(_notes.reverse());
      setNotesLoading(false);
    }

    fetchNotes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field_id])


  useEffect(() => {
    const _notes = provisions.map((p) => {
      let _note = {notes: p.notes, date: p.date, user: p.user, phase: p.phase}
      return _note
    })

    setNotes(_notes);
    setFilteredNotes(_notes.reverse());

    var _timeline = _notes.map((item) => {
      return {
        children: 
          <>
            <Typography.Text danger italic style={{fontSize: 12}}>{item.date} </Typography.Text>
            <Typography.Text>{t(`components.${item.phase?.toLowerCase()}`)}</Typography.Text>
            <br />
            <Typography.Text>{item.notes}</Typography.Text>
          </>,
        annotation_date: item.date,
        //dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
        //dot: item.annotationDate
        color: "tangerine",
        dot:  <Tooltip title={item.user.name}><Avatar size="small" style={{ backgroundColor: '#f56a00' }}>{item.user?.name[0] + item.user?.name.split(" ")[1][0]}</Avatar></Tooltip>
      }
    })

    _timeline.sort(function(a, b) {
      var keyA = new Date(a.annotation_date),
        keyB = new Date(b.annotation_date);
      // Compare the 2 dates
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
      return 0;
    });
    setTimelineItems(_timeline)
    setNotesLoading(false);
  }, [provisions])


  
  useEffect(() => {
    if (selected_index ) {
      
    }
  }, [selected_index])



  //TODO - Don't support notes and annotations differently - all notes are also annotations for now
  const onClick = (e) => {
    setSelectedSubMenu(e)
  };
  // Dropdown items
  const items = [
    {
      key: '1',
      label: _menu_props['all'],
      onClick: (e) => onClick({type: "all"})
    },
    {
      key: '2',
      label: _menu_props['timeline'],
      onClick: () => onClick({type: "timeline"})
    },
  ];

  const onSearch = (e) => {
    if (!e) return setFilteredNotes(notes); // search bar cleared

    const value =  e.target.value
    const filtered = notes.filter((item) => {
      
      return item.notes?.toString().toLowerCase().includes(value.toLowerCase()) || 
             item.user?.name.toLowerCase().includes(value.toLowerCase())
    });

    setFilteredNotes(filtered);
  };  

  const onDatePickerChange = (date) => {
    // if no date is selected, show all notes - means the date picker is cleared
    if (!date) return setFilteredNotes(notes);

    date = date.format("YYYY-MM-DD");
    const filtered = notes.filter((item) =>
      item.date?.includes(date)
    );

    setFilteredNotes(filtered);
  }

  return (
    <>
      {
        selected_user_asset && selected_index && 
        (selected_index_overlay && selected_index_overlay.tile_url ? 
          <Layout style={{textAlign: "center", padding: 20, alignItems: "center", width: 300, height: 400}}> 
            <Typography.Title level={4} style={{color: "#282c34"}}>
              {t('general.fieldinfo_on_date',{ field_name: selected_user_asset?.name, date: moment(selected_index_overlay.date).format("DD MMMM YYYY") })}
            </Typography.Title>

            {/* <RcResizeObserver
              key="resize-observer"
              onResize={(offset) => {
                setResponsive(offset.width < 596);
              }}
            >
              <StatisticCard.Group direction={responsive ? 'column' : 'row'}>
                <StatisticCard
                  statistic={{
                    title: '总流量(人次)',
                    value: 601986875,
                  }}
                />
                  <StatisticCard
                  statistic={{
                    title: '免费流量',
                    value: 1806062,
                    description: <Statistic title="占比" value="38.5%" />,
                  }}
                  chart={
                    <IndexGraph index={selected_index[0]} current_field={selected_user_asset} duration={"10"} /> 
                  }
                  chartPlacement="left"
                />
                <StatisticDivider type={responsive ? 'horizontal' : 'vertical'} />
                <StatisticCard
                  statistic={{
                    title: '付费流量',
                    value: 3701928,
                    description: <Statistic title="占比" value="61.5%" />,
                  }}
                  chart={
                    <img
                      src="https://gw.alipayobjects.com/zos/alicdn/ShNDpDTik/huan.svg"
                      alt="百分比"
                      width="100%"
                    />
                  }
                  chartPlacement="left"
                />
                <StatisticCard
                  statistic={{
                    title: '免费流量',
                    value: 1806062,
                    description: <Statistic title="占比" value="38.5%" />,
                  }}
                  chart={
                    <img
                      src="https://gw.alipayobjects.com/zos/alicdn/6YR18tCxJ/huanlv.svg"
                      alt="百分比"
                      width="100%"
                    />
                  }
                  chartPlacement="left"
                />
              </StatisticCard.Group>
            </RcResizeObserver> */}
            <FieldHealthPieChart field={selected_user_asset} current_date={selected_index_overlay.date} index={"ndvi"} />
          </Layout>
          :
          <Spin style={{textAlign: "center", padding: 20, alignItems: "center", width: 300, height: 400}} size="default"/>)
      }
      <Divider orientation="left" style={{color: "#282c34"}}>
          <Dropdown menu={{items}} trigger={["click"]}>
            <span>
                {_menu_props[selected_submenu.type]} <DownOutlined />
            </span>
          </Dropdown>
      </Divider>

      {notes_loading ? 
        <Layout style={{margin: "auto"}}>
          <Spin />
        </Layout>
        :
      
    
        selected_submenu.type === "all" ? 
        //TODO - Annotations should be its own component with callbacks to the parent, Notes
        // TODO - names can change, Notes, Annotations, confusing.
        //https://agcurate.atlassian.net/browse/AGCC-129
        <>
          <Layout style={{backgroundColor: "#f5f5f5", color: "#282c34", position: 'sticky', top: "0", width:"100%", zIndex: 1005, padding: 5}}>
            <Space direction="horizontal" style={{textAlign: "center", padding: 5, justifyContent: "space-between"}}>
              <Search  placeholder="Search text" allowClear onChange={onSearch} style={{ width: "100%" }} />
              <DatePicker
                onChange={(date) => {
                  onDatePickerChange(date);
                }}
                cellRender={(current) => {
                  const style = {};
                  if (annotations[current.format("YYYY-MM-DD")]) {
                    style.border = '1px solid #1677ff';
                    style.borderRadius = '50%';
                  }
                  
                  return (
                    <div className="ant-picker-cell-inner" style={style}>
                      {current.date()}
                    </div>
                  );
                  
                }}
              />
            </Space>
          </Layout>

          {
          filtered_notes.length > 0 ?  // If there are no notes to show, show Empty component
          
          <Layout style={{backgroundColor: "#f7f7f8", color: "#282c34"}}>
            <List
              dataSource={filtered_notes}
              pagination={{
                onChange: (page) => {
                  // FIXME - Do smth with page
                },
                responsive: true,
                pageSize: 6,
                position: "bottom",
                align: "center",
                color: "#282c34"
              }}
              size="small"
              itemLayout="horizontal"
              renderItem={(item) => (
                <List.Item size="small">
                  <List.Item.Meta
                    avatar={ 
                      <Avatar style={{ backgroundColor: '#f56a00' }}>{item.user.name[0] + item.user.name.split(" ")[1][0]}</Avatar>
                    }

                    title={
                      <Space size="large" style={{ width: '100%', justifyContent: "space-between"}}>
                      <Typography.Text style={{fontSize: 16}}> 
                        {item.user.name}
                      </Typography.Text>
                      <Typography.Text italic disabled style={{fontSize: 10}}>
                        {moment(item.date).format("DD MMM YYYY")}
                      </Typography.Text>
                      </Space>
                    }
                    
                    description={
                      <Space size="small" direction="vertical">
                        <Typography.Text italic style={{fontSize: 13}}>
                          {item.annotationDate}
                        </Typography.Text>
                        <Typography.Paragraph italic type="secondary" ellipsis={{rows: 2, expandable: true, symbol: 'more'}}>
                          {item.notes ? item.notes : t('general.no_notes')}
                        </Typography.Paragraph>
                      </Space>
                    }
                  />
                </List.Item>
              )}
            />
          </Layout>
          :
          //TODO - Empty should be its own component, with Create New button linked to a callback in the parent.
          //TODO https://agcurate.atlassian.net/browse/AGCC-127
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{ height: 60 }}
            description={
              <span>
                { notes.length > 0 ? "Not found" : "No notes or annotations" }
              </span>
            }
          >
            <Button disabled type="primary">Create Now</Button>
          </Empty>
          }
        </>
       :

        timeline_items.length > 0 ?
        // TODO - Timeline should be its own component with its own UI and other related logic
        //https://agcurate.atlassian.net/browse/AGCC-128
        <Layout style={{textAlign: "center", paddingLeft: 40,  paddingRight: 40, paddingTop: 10, alignItems: "center"}}>
          <Timeline
            mode="alternate"
            items={timeline_items}
          />
        </Layout>
        
        :
        
        //TODO - Empty should be its own component, with Create New button linked to a callback in the parent.
        //TODO https://agcurate.atlassian.net/browse/AGCC-127
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{ height: 60 }}
          description={
            <span>
              
              { 
                //if there are notes but filtered is empty, then search resulted in nothing, hence "not found"
                notes.length > 0 ? "Not found" : "No notes or annotations" 
              }
            </span>
          }
        >
          <Button type="primary">Create Now</Button>
        </Empty>
        

      
      }
    </>
    );
  }

export default Notes;